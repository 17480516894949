import React, { ReactNode, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CircularProgress, Button } from '@mui/material'
import VipMenu from 'admin/components/regular/VipMenu'
import { useParams } from 'react-router-dom'
import { mobileCss } from 'utils/theme'

interface Props {
  children: ReactNode
}

const VipPageWrapper = ({ children }: Props) => {
  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() =>{
      setLoading(false)
    }, 500)
  }, [])

  return loading
    ? (
      <EmptyState>
        <CircularProgress size={60} />
      </EmptyState>
    )
    : (
      <Wrapper>
        <VipMenu />
        <Content>
          {children}
        </Content>
      </Wrapper>
    )
}

export default VipPageWrapper

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
  position: relative;

  ${mobileCss(`
    > div:first-child {  /* This targets the Header component */
      position: sticky;
      top: 0;
      background: white;
      z-index: 100;
      margin: -16px -16px 21px;
      padding: 12px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
  `)};
`

const EmptyState = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
