import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
// @ts-ignore
import logo from 'assets/prb-logo.png'
import { Link, useParams } from 'react-router-dom'
import { mobileCss } from 'utils/theme'
import { Drawer } from '@mui/material'
import NamesNavigation from 'admin/components/regular/NamesNavigation'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import { supabase } from 'utils/supabase'
import { Modal, TextField, Button } from '@mui/material'
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast'

const VipMenu = () => {
  
  const { code } = useParams()
  
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [vips, setVips] = useState<VipTableRowTypes[]>([])
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [formData, setFormData] = useState({ message: '' })
  const [formErrors, setFormErrors] = useState<{message?: string}>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const getVips = async () => {
    try {
      const response = await supabase
        .from('vip_links')
        .select('*')
      
      const data = response.data as VipTableRowTypes[]
      
      setVips(data)
      
    } catch (err) {
      console.error(err)
    }
  }
  
  useEffect(() => {
    getVips()
  }, [])
  
  // Initialize EmailJS immediately when the component mounts
  useEffect(() => {
    const initEmailJS = async () => {
      try {
        await emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY!)
        console.log('EmailJS initialized')
      } catch (error) {
        console.error('EmailJS initialization error:', error)
      }
    }
    initEmailJS()
  }, [])
  
  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1036)
    }
    
    window.addEventListener('resize', handleResize)
    handleResize()
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!formData.message.trim()) {
      setFormErrors({ message: 'Wiadomość jest wymagana' })
      return
    }

    try {
      setIsSubmitting(true)
      
      const currentVip = vips.find(v => v.code === code)
      
      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        {
          from_name: currentVip?.vipFullName || 'Nieznany użytkownik',
          from_email: currentVip?.vipEmail || 'brak@email.com',
          message: formData.message,
          user_code: code || 'brak kodu',
          to_name: 'Administrator WhoIsWho'
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      )

      if (response.status === 200) {
        toast.success('Wiadomość została wysłana')
        setIsContactModalOpen(false)
        setFormData({ message: '' })
      } else {
        toast.error('Wystąpił błąd podczas wysyłania wiadomości')
      }
    } catch (error) {
      console.error('Email error:', error)
      toast.error('Wystąpił błąd podczas wysyłania wiadomości')
    } finally {
      setIsSubmitting(false)
    }
  }
  
  return (
    <Wrapper>
      {isMobile ? (
        <MobileNav>
          <Hamburger open={open} onClick={toggleOpen}>
            <Bar open={open} />
          </Hamburger>
          <Logo src={logo} alt='who is who' />
          <Drawer anchor='left' open={open} onClose={toggleOpen}>
            <DrawerContent>
              <Hamburger open={open} onClick={toggleOpen}>
                <Bar open={open} />
              </Hamburger>
              <LinksWrapper>
                <StyledLink to={`/${code}`}>Strona główna</StyledLink>
                {code && <StyledLink to={`/${code}/edytuj`}>Edytuj swój profil</StyledLink>}
                {code && <StyledLink to={`/${code}/instrukcja`}>Instrukcja</StyledLink>}
                {code && <StyledLink to="#" onClick={(e) => {
                  e.preventDefault()
                  setOpen(false)
                  setIsContactModalOpen(true)
                }}>Zgłoś problem</StyledLink>}
                <NamesNavigation vips={vips} onLinkClick={toggleOpen}/>
              </LinksWrapper>
            </DrawerContent>
          </Drawer>
        </MobileNav>
      ) : (
        <DesktopNav>
          <Logo src={logo} alt='who is who' />
          <LinksWrapper>
            <StyledLink to={`/${code}`}>Strona główna</StyledLink>
            {code && <StyledLink to={`/${code}/edytuj`}>Edytuj swój profil</StyledLink>}
            {code && <StyledLink to={`/${code}/instrukcja`}>Instrukcja</StyledLink>}
            {code && <StyledLink to="#" onClick={(e) => {
              e.preventDefault()
              setIsContactModalOpen(true)
            }}>Zgłoś problem</StyledLink>}
            <NamesNavigation vips={vips} onLinkClick={toggleOpen}/>
          </LinksWrapper>
        </DesktopNav>
      )}

      <Modal
        open={isContactModalOpen}
        onClose={() => {
          setIsContactModalOpen(false)
          setFormData({ message: '' })
          setFormErrors({})
        }}
        aria-labelledby="contact-form-modal"
      >
        <ModalContent>
          <ModalTitle>Zgłoś problem</ModalTitle>
          <Form onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              label="Wiadomość"
              multiline
              rows={4}
              value={formData.message}
              onChange={(e) => {
                setFormData({ message: e.target.value })
                if (formErrors.message) setFormErrors({})
              }}
              error={!!formErrors.message}
              helperText={formErrors.message}
              variant="outlined"
            />
            <ButtonsWrapper>
              <Button 
                variant="outlined" 
                onClick={() => {
                  setIsContactModalOpen(false)
                  setFormData({ message: '' })
                  setFormErrors({})
                }}
                sx={{ marginRight: 1 }}
              >
                Anuluj
              </Button>
              <Button 
                variant="contained" 
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Wysyłanie...' : 'Wyślij'}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ModalContent>
      </Modal>
    </Wrapper>
  )
}

export default VipMenu

const Wrapper = styled.div`
  width: auto;
  background: #f2f2f2;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 1000;

  ${mobileCss(`
  width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `)};
`
const DesktopNav = styled.div`
  max-width: 180px;
`
const MobileNav = styled.div`
`

const Logo = styled.img`
  width: 150px;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 1036px) {
    display: none;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLink = styled(Link)`
  padding: 12px;
  border-radius: 8px;
  transition: all .3s;
  text-decoration: none;
  color: #444;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`
const Hamburger = styled.div<{ open: boolean }>`
  display: none;
  flex-direction: column;
  gap: 5px;
  width: 30px;
  cursor: pointer;
  z-index: 200;

  ${mobileCss(`
    display: flex;
  `)};

  &::before,
  &::after,
  div {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.secondary};
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  ${({ open }) =>
          open &&
          css`
            &::before {
              transform: rotate(45deg) translateY(10px);
            }

            &::after {
              transform: rotate(-45deg) translateY(-10px);
            }

            div {
              opacity: 0;
            }
          `}
`
const Bar = styled.div<{ open: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: opacity 0.3s ease;
`
const DrawerContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1036px) {
    display: none;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 20px;
  margin-left: ${({ theme }) => theme.isMobile ? '0' : '180px'};
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  outline: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`

const ModalTitle = styled.h2`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`
