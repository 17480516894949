import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { useParams } from 'react-router-dom'
import VipPageWrapper from 'admin/components/VipPageWrapper'
import VipForm from 'admin/components/VipForm'

const EditVip = () => {
  const [vip, setVip] = useState(undefined)
  const { code } = useParams<{ code: string }>()
  
  const fetchVip = async () => {
    try {
      const response = await supabase.from('vip_links').select().eq('code', code)
      const data = response.data ? response.data[0] : null
      
      if (data) {
        setVip(data)
      }
    } catch (err: any) {
      console.error('Error fetching VIP:', err.message)
    }
  }
  
  useEffect(() => {
    fetchVip()
  }, [])

  return (
    <VipPageWrapper>
      <VipForm vip={vip} />
    </VipPageWrapper>
  )
}

export default EditVip
